import useIsomorphicLayoutEffect from 'hooks/useIsomorphicLayoutEffect'
import useSlug from 'hooks/useSlug'
import {
  isAbsent,
  isDiff,
  isNonEmptyArray,
  isNonEmptyString,
  isNumber,
  isPresent,
  isSame,
  toValues,
} from 'lib/utils'
import inPolygon from 'point-in-polygon'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  useAddCouponMutation,
  useGetAddressSuggestionsMutation,
  useGetAnonTokenQuery,
  useGetCitiesQuery,
  useGetUserInfoQuery,
  useLazyGetAboutQuery,
  useLazyGetBannersQuery,
  useLazyGetCartQuery,
  useLazyGetCategoryListQuery,
  useLazyGetMenusQuery,
  useLazyGetProductListQuery,
  useSetAddressMutation,
  useSetDeliveryDateMutation,
  useSetUniqueIdMutation,
} from 'store/api/actions'
import { selectCities, selectUser } from 'store/api/selectors'
import {
  IAuthState,
  removeUserToken,
  selectAnonToken,
  selectCity,
  selectLng,
  selectUserToken,
  setCity,
  setLng,
} from 'store/auth'
import {
  selectIsBrowser,
  setBreakpoints,
  setContext,
  setHistory,
} from 'store/ui'

let AppWatcher = () => {
  let dispatch = useDispatch()
  let { router, queryLng } = useSlug()

  let anonToken = useSelector(selectAnonToken)
  let userToken = useSelector(selectUserToken)
  let isBrowser = useSelector(selectIsBrowser)
  let lng = useSelector(selectLng) ?? 'ru'
  let city = useSelector(selectCity)
  let cities = useSelector(selectCities(queryLng))

  let [setDeliveryDate] = useSetDeliveryDateMutation()

  useIsomorphicLayoutEffect(() => {
    isAbsent(lng) && dispatch(setLng('ru'))
  }, [lng])

  let [getProducts] = useLazyGetProductListQuery()
  let [getCategories] = useLazyGetCategoryListQuery()
  let [getMenus] = useLazyGetMenusQuery()
  let [getBanners] = useLazyGetBannersQuery()
  let [getAbout] = useLazyGetAboutQuery()
  let { isSuccess: isGetAnonTokenSuccess } = useGetAnonTokenQuery(
    queryLng,
    {
      skip:
        !isBrowser || isPresent(anonToken) || isPresent(userToken),
    }
  )
  let { isSuccess: isGetUserInfoSuccess, error: getUserInfoError } =
    useGetUserInfoQuery(queryLng, {
      skip: !isBrowser || isAbsent(userToken),
    })
  let [getCart, { isSuccess: isGetCartSuccess, data: getCartData }] =
    useLazyGetCartQuery()
  let { isSuccess: isGetCitiesSuccess } = useGetCitiesQuery(
    queryLng,
    { skip: !isBrowser || !isGetCartSuccess }
  )

  let [addCoupon] = useAddCouponMutation()

  useEffect(() => {
    setDeliveryDate({
      lng: queryLng,
      date: '',
    }).unwrap()
  }, [])

  useEffect(() => {
    let f = () => {
      const cartDeliveryTime =
        getCartData?.data.cart.delivery?.deliveryTime
      if (
        isNonEmptyString(getCartData?.data.cart.action_date) &&
        isAbsent(
          getCartData?.data.cart.cartMessages.find((x) =>
            x.text[1]?.startsWith('Ближаешее')
          )
        ) &&
        isNumber(cartDeliveryTime)
      ) {
        const deliveryTime =
          isPresent(cartDeliveryTime) &&
          cartDeliveryTime !== 0 &&
          cartDeliveryTime >= 60
            ? cartDeliveryTime * 60 * 1000
            : 60 * 60 * 1000

        let firstAvailableDate = new Date(
          new Date().getTime() + deliveryTime
        )

        let actionDate = getCartData!.data.cart.action_date

        new Date(actionDate) < firstAvailableDate &&
          setDeliveryDate({
            lng: queryLng,
            date: '',
          }).unwrap()
      }
    }

    f()

    let interval = setInterval(() => f(), 60000)

    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getCartData?.data.cart.action_date,
    getCartData?.data.cart.delivery,
    queryLng,
    setDeliveryDate,
  ])

  useEffect(() => {
    let onRouteChange = (path: string) => {
      if (path !== '/checkout') dispatch(setHistory(path))
    }

    router.events.on('routeChangeComplete', onRouteChange)

    return () => {
      router.events.off('routeChangeComplete', onRouteChange)
    }
  }, [dispatch, router.events])

  useEffect(() => {
    isSame((getUserInfoError as any)?.status, 403) &&
      dispatch(removeUserToken())
  }, [dispatch, getUserInfoError])

  useEffect(() => {
    if (isBrowser) {
      void getProducts(queryLng)
      void getCategories(queryLng)
      void getMenus(queryLng)
      void getBanners(queryLng)
      void getAbout(queryLng)
    }
  }, [
    city,
    getCategories,
    getMenus,
    getProducts,
    getBanners,
    getAbout,
    isBrowser,
    queryLng,
  ])

  useEffect(() => {
    isGetAnonTokenSuccess && getCart(queryLng)
  }, [getCart, isGetAnonTokenSuccess, queryLng])

  useEffect(() => {
    isBrowser &&
      (isPresent(anonToken) || isPresent(userToken)) &&
      void getCart(queryLng)
  }, [anonToken, userToken, isBrowser, getCart, queryLng])

  useIsomorphicLayoutEffect(() => {
    dispatch(
      setContext({
        rem: parseFloat(
          getComputedStyle(document.documentElement).fontSize
        ),
      })
    )
  }, [])

  useEffect(() => {
    if (isGetCitiesSuccess && isNonEmptyArray(cities)) {
      const mainCity = cities[0].code
      if (
        isAbsent(city) ||
        (isPresent(city) && isDiff(city, mainCity))
      ) {
        dispatch(setCity(mainCity))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGetCitiesSuccess, city, cities])

  useIsomorphicLayoutEffect(() => {
    let handleResize = () => {
      document.documentElement.style.setProperty(
        '--vh',
        `${(window.innerHeight * 0.01).toFixed(2)}px`
      )

      dispatch(
        setBreakpoints({
          isSm: window.innerWidth < 768,
          isMd: window.innerWidth >= 768,
          isLg: window.innerWidth >= 1024,
          isXl: window.innerWidth >= 1280,
        })
      )
    }

    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      document.removeEventListener('resize', handleResize)
    }
  }, [])

  const [setUniqueId] = useSetUniqueIdMutation()

  useEffect(() => {
    document.body.classList?.remove('loading')
  }, [])

  useEffect(() => {
    isBrowser &&
      (isPresent(anonToken) || isPresent(userToken)) &&
      setUniqueId(queryLng)
  }, [anonToken, userToken, isBrowser, setUniqueId, queryLng])

  const user = useSelector(selectUser(queryLng))
  let [setAddress] = useSetAddressMutation()
  let [getAddressSuggestions] = useGetAddressSuggestionsMutation()

  useEffect(() => {
    const f = async () => {
      if (
        isPresent(userToken) &&
        isNonEmptyArray(user.profiles) &&
        isNonEmptyArray(cities)
      )
        await getAddressSuggestions({
          q: user.profiles[0]!.address,
          lng: queryLng,
        })
          .unwrap()
          .then(async (x) => {
            if (isNonEmptyArray(x)) {
              let coords = [
                Number(x[0]!.data.geo_lat),
                Number(x[0]!.data.geo_lon),
              ]
              let selectedCity: IAuthState['city'] = null
              for (let c of cities) {
                for (let p of toValues(
                  c?.delivery_zone.day.polygons[0] ?? {}
                )) {
                  let isFound = inPolygon(coords, p.coordinates[0]!)
                  if (isFound) {
                    selectedCity = c.code
                    break
                  }
                }
                if (isPresent(selectedCity)) break
              }
              await setAddress({
                address: x[0]!,
                lng: queryLng,
                city: selectedCity!,
              })
              dispatch(setCity(selectedCity))
              void getCart(queryLng)
            }
            return
          })
    }
    void f()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGetCitiesSuccess, isGetUserInfoSuccess])

  // Apply coupon on every page which contains query param ?coupon=my_coupon
  useEffect(() => {
    if (isBrowser && (isPresent(anonToken) || isPresent(userToken))) {
      let coupon = router.query['coupon']
      if (coupon) {
        addCoupon({ coupon: coupon.toString(), lng: queryLng })
      }
    }
  }, [router, queryLng, addCoupon, isBrowser, anonToken, userToken])

  return null
}

export default AppWatcher
